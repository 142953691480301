import React from "react";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { PROPERTY_FILTERS } from "properties/searchBar/filters/_utils/filtersUtils";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { DEFAULT_LINE, DEFAULT_PAGE_SIZE } from "_common/resources/BaseResourceStore";
import { PropertiesListLoader } from "properties/listing/PropertiesListLoader";
import { PropertiesNearListProperties } from "properties/listing/PropertiesNearListProperties";

export function PropertiesNearList() {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);

    const filters = listStore.filters.find((filter) => filter.id === PROPERTY_FILTERS.TYPE)?.value ?? [];
    if (geoZonesStore.geoZone?._id) {
        propertiesStore.fetchNearList(
            filters,
            geoZonesStore.geoZone._id,
            (DEFAULT_LINE - Math.ceil((listStore.count ?? 0) / (DEFAULT_PAGE_SIZE / DEFAULT_LINE))) *
                (DEFAULT_PAGE_SIZE / DEFAULT_LINE),
        );
    }

    return (
        <LoadableFromLoading
            loading={propertiesStore.nearPropertiesState}
            fallback={<PropertiesListLoader />}
            renderer={(_status, error) => {
                if (error) return null;
                return <PropertiesNearListProperties />;
            }}
        />
    );
}
