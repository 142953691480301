import React from "react";
import { Meta } from "_common/_utils/Meta";
import { useTranslation } from "react-i18next";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import i18next from "i18next";
import { URLS } from "_configs/URLS";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { getI18nExpByLang } from "_common/_utils/pageUtils";

type Props = {
    purpose?: PROPERTY_PURPOSE;
};

export function PropertiesMeta(props: Props) {
    const { t } = useTranslation();
    const { isCityPage, isProvincePage, propertyTypeLocalized, propertyTypeKey, page } = useSearchPageParams();
    const propertiesStore = usePropertiesStore();
    const pageParams = page && page > 1 ? `p_${page}` : "";
    const title = isProvincePage
        ? t("propertiesPage.meta.title", {
              region: propertiesStore.addressParams.region,
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          })
        : undefined;
    const description = isProvincePage
        ? t("propertiesPage.meta.description", {
              region: propertiesStore.addressParams.region,
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          }) +
          t(`propertiesPage.meta.purpose.${props.purpose}`, {
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          })
        : undefined;
    const alternateUrls = [];
    // alternates : url pour chaque langue, attention à ne pas utiliser les formes localized ou i18next
    for (const lang of Object.keys(sharedConfig.languages)) {
        let route = "";
        const routeLocalized = getI18nExpByLang(
            lang,
            props.purpose === PROPERTY_PURPOSE.BUY ? "routes.buy" : "routes.rent",
        );
        const typeLocalized = getI18nExpByLang(lang, "property.typesUrl." + propertyTypeKey).toLowerCase();

        if (isCityPage) {
            route = `${sharedConfig.appUrl}/${lang}/${routeLocalized}/${reformatStringForUrls(
                propertiesStore.addressParams.region,
            )}/${reformatStringForUrls(propertiesStore.addressParams.city)}${
                propertyTypeKey ? `/${typeLocalized}` : ""
            }${pageParams ? `/${pageParams}` : ""}`;
        } else if (isProvincePage) {
            route = `${sharedConfig.appUrl}/${lang}/${routeLocalized}/${reformatStringForUrls(
                propertiesStore.addressParams.region,
            )}${propertyTypeKey ? `/${typeLocalized}` : ""}${pageParams ? `/${pageParams}` : ""}`;
        } else {
            route = `${sharedConfig.appUrl}/${lang}/${routeLocalized}${propertyTypeKey ? `/${typeLocalized}` : ""}${
                pageParams ? `/${pageParams}` : ""
            }`;
        }
        alternateUrls.push({
            url: route,
            lang: lang as TLang,
        });
    }
    const url = isCityPage
        ? URLS[props.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
              i18next.language,
              reformatStringForUrls(propertiesStore.addressParams.region),
              reformatStringForUrls(propertiesStore.addressParams.city),
              propertiesStore.addressParams?.neighbourhood
                  ? reformatStringForUrls(propertiesStore.addressParams?.neighbourhood)
                  : propertyTypeLocalized
                  ? propertyTypeLocalized
                  : pageParams,
              propertiesStore.addressParams?.neighbourhood
                  ? propertyTypeLocalized
                      ? propertyTypeLocalized
                      : pageParams
                  : undefined,
              propertiesStore.addressParams?.neighbourhood
                  ? propertyTypeLocalized
                      ? pageParams
                      : undefined
                  : undefined,
          ).toLowerCase()
        : isProvincePage
        ? URLS[props.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
              i18next.language,
              reformatStringForUrls(propertiesStore.addressParams.region),
              propertyTypeLocalized ?? pageParams,
              propertyTypeLocalized ? pageParams : undefined,
          ).toLowerCase()
        : URLS[props.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
              i18next.language,
              propertyTypeLocalized ?? pageParams,
              propertyTypeLocalized ? pageParams : undefined,
          ).toLowerCase();
    return <Meta title={title} description={description} url={url} alternateUrls={alternateUrls} />;
}
