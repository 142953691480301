import React, { lazy, useEffect, useRef, useState } from "react";
import { PROPERTY_EVENT, TPropertyCardMdl } from "properties/_models/PropertyMdl";
import styles from "./_css/propertyCard.module.css";
import "./_css/propertyCard.css";
import SwiperCore, { Navigation } from "swiper";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import clsx from "clsx";
import { URLS } from "_configs/URLS";
import { EditCardBtn } from "users/dashboard/edit/EditCardBtn";
import { Helmet } from "react-helmet-async";
import Suspenser from "_common/loaders/Suspenser";
import { getWebpFile } from "_common/_utils/fileUtils";
import { useOnScreen } from "_common/_utils/useOnScreen";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { useLocation } from "react-router";
import { PLACEMENT_EVENT, PLACEMENT_PRINTED_EVENT } from "placements/_models/PlacementsMdl";
import { observer } from "mobx-react";
import { PropertyGalleryCardFallback } from "properties/PropertyGalleryCardFallback";
import { PropertyCardContent } from "properties/PropertyCardContent";
import { refreshPage } from "_common/_utils/pageUtils";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation]);

type Props = {
    property: TPropertyCardMdl;
    isMapCard?: boolean;
    isFeaturedCard?: boolean;
    isPrimaryCard?: boolean;
    isSimilarCard?: boolean;
    isEditable?: boolean;
    onEdit?: () => void;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
};

const LazyPropertyGalleryCard = lazy(() => import("properties/PropertyGalleryCard"));
const LazyPropertyCardTags = lazy(() => import("properties/PropertyCardTags"));

const _PropertyCard = observer((props: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const url = useLocation().pathname;
    const { isIntersecting } = useOnScreen(ref, 0.5);

    useEffect(() => {
        if (isIntersecting) {
            void analyticsUtils.tracksEventOfItem(PROPERTY_EVENT.PRINTED, props.property._id, url);

            if (props.property.placementId && (props.isFeaturedCard || props.property.isPremium)) {
                if (props.isFeaturedCard) {
                    void analyticsUtils.tracksEventOfItem(
                        PLACEMENT_PRINTED_EVENT.PRINTED_FEATURED,
                        props.property.placementId,
                        url,
                    );
                } else if (props.property.isPremium) {
                    void analyticsUtils.tracksEventOfItem(
                        PLACEMENT_EVENT.PRINTED_PREMIUM,
                        props.property.placementId,
                        url,
                    );
                }
            }
        }
    }, [isIntersecting]);

    const isFeaturedProperty = !!props.property.isFeatured;

    let borderColor = "lightgrey";
    let borderWidth = "1px";
    if (isFeaturedProperty) borderColor = "#ffc435";
    if (isFeaturedProperty) borderWidth = "2px";
    const onClick = (e: React.MouseEvent) => {
        if (props.property.placementId && (props.isFeaturedCard || props.property.isPremium)) {
            if (props.isFeaturedCard) {
                void analyticsUtils.tracksEventOfItem(
                    PLACEMENT_EVENT.VISITED_FEATURED,
                    props.property.placementId,
                    url,
                );
            } else if (props.property.isPremium) {
                void analyticsUtils.tracksEventOfItem(PLACEMENT_EVENT.VISITED_PREMIUM, props.property.placementId, url);
            }
        }

        if (props.isSimilarCard) {
            e.preventDefault();
            refreshPage(URLS.property(props.property.localized.urlAlias));
        }
    };
    return (
        <div
            id="propertyCard"
            ref={ref}
            className={clsx(styles.container, "flex_column flex-1", {
                [styles.containerMap]: props.isMapCard,
                [styles.containerFeatured]: props.isFeaturedCard || props.isSimilarCard,
                ["m_0"]: props.isFeaturedCard,
            })}
            style={{ borderColor, borderWidth }}
            onMouseOver={props.onMouseOver}
            onMouseLeave={props.onMouseLeave}
        >
            <Helmet>
                <link
                    rel="preload"
                    as="image"
                    href={getWebpFile(props.property.photos?.[0]?.thumbnail) ?? props.property.photos?.[0]?.url}
                />
            </Helmet>
            <Link
                to={URLS.property(props.property.localized.urlAlias)}
                onClick={onClick}
                aria-label={`Property link for "${props.property.localized.title}"`}
                aria-labelledby={`Property link for "${props.property.localized.title}"`}
                className={clsx("flex-1 flex position_relative", styles.containerGallery, {
                    [styles.containerGalleryMap]: props.isMapCard,
                })}
            >
                <Suspenser fallback={<div />}>
                    <LazyPropertyCardTags property={props.property} isMapCard={props.isMapCard} />
                </Suspenser>
                <Suspenser fallback={<PropertyGalleryCardFallback {...props} />}>
                    <LazyPropertyGalleryCard {...props} />
                </Suspenser>
            </Link>
            <PropertyCardContent {...props} onClick={onClick} />
        </div>
    );
});

export function PropertyCard(props: Props) {
    const [isOverCard, setIsOverCard] = useState(false);
    if (!props.property) return null;
    return props.isEditable ? (
        <div
            className={"position_relative"}
            onMouseEnter={() => setIsOverCard(true)}
            onMouseLeave={() => setIsOverCard(false)}
        >
            <EditCardBtn
                isOverCard={isOverCard}
                link={URLS.property(props.property.localized.urlAlias)}
                onEdit={props.onEdit}
                onClose={() => setIsOverCard(false)}
            />
            <_PropertyCard {...props} />
        </div>
    ) : (
        <_PropertyCard {...props} />
    );
}
