import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TVirtualAssistantForm } from "virtualAssistant/VirtualAssistantForm";
import { CheckBoxInput } from "_common/ui/forms/CheckBoxInput";
import { VirtualAssistantQuestionLabel } from "virtualAssistant/VirtualAssistantQuestionLabel";
import styles from "./_css/virtualAssistantStepOneForm.module.css";
import { GeozonesMultiSelect } from "virtualAssistant/GeozonesMultiSelect";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";

export function VirtualAssistantStepOneForm() {
    const form = useFormContext<TVirtualAssistantForm>();
    const { t } = useTranslation();
    console.log("form.watch", form.watch("geoZone"));
    return (
        <div className={styles.container}>
            <div>
                <VirtualAssistantQuestionLabel label={t("virtualAssistant.stepOneForm.firstQuestionLabel")} />
                <div className={styles.title}>{t("virtualAssistant.stepOneForm.firstQuestionTitle")}</div>
                <Controller
                    as={CheckBoxInput}
                    control={form.control}
                    name={"purpose"}
                    value={form.watch("purpose")}
                    rules={{
                        required: t<string>("errors.forms.required"),
                    }}
                    items={[
                        { value: PROPERTY_PURPOSE.BUY, label: "virtualAssistant.stepOneForm.buy" },
                        { value: PROPERTY_PURPOSE.RENT, label: "virtualAssistant.stepOneForm.rent" },
                    ]}
                    containerClassName={styles.checkBoxInput}
                />
            </div>
            <div>
                <VirtualAssistantQuestionLabel label={t("virtualAssistant.stepOneForm.secondQuestionLabel")} />
                <div className={styles.title}>{t("virtualAssistant.stepOneForm.secondQuestionTitle")}</div>
                <Controller name="geoZone" control={form.control} as={GeozonesMultiSelect} />
            </div>
        </div>
    );
}
