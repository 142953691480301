import React, { lazy, useEffect, useRef } from "react";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { PropertiesMeta } from "properties/listing/PropertiesMeta";
import { useHistory, useLocation } from "react-router-dom";
import { PageNotFound } from "pages/PageNotFound";
import { deleteItemInitialSate } from "_common/_utils/initialStateUtils";
import styles from "../_css/propertiesPage.module.css";
import { getPageNumberFromUrl } from "_common/_utils/pageUtils";
import Suspenser from "_common/loaders/Suspenser";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { scrollTop } from "_common/_utils/ScrollTop";
import { PropertiesViewHeader } from "properties/listing/PropertiesViewHeader";
import clsx from "clsx";
import { PageRendererContext, usePropertiesPageContext } from "properties/PropertiesPageContext";
import FeaturedPropertiesSrpsSection from "properties/searchBar/featured/FeaturedPropertiesSrpsSection";
import { MAP_OR_LIST_SELECTED, PropertiesView } from "properties/listing/PropertiesView";
import { useScrollPositionAlt } from "_common/_utils/hookUtils";

const LazyPropertiesSearchBar = lazy(() => import("properties/searchBar/PropertiesSearchBar"));

const _PropertiesPage = () => {
    const propertiesStore = usePropertiesStore();
    const location = useLocation();
    const history = useHistory();
    const { mapOrListSelected, setMapOrListSelected } = usePropertiesPageContext();
    const scrollPosition = useScrollPositionAlt();

    const listStore = propertiesStore.getListStore(
        propertiesStore.purpose,
        undefined,
        undefined,
        true,
        false,
        undefined,
        getPageNumberFromUrl(location.pathname) ?? 1,
    );

    const propertiesPageContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchSearchProperties(propertiesStore, listStore, history, false, true);
        if (localStorage.getItem("displayListing") && localStorage.getItem("displayListing") !== mapOrListSelected) {
            setMapOrListSelected(
                (localStorage.getItem("displayListing") as MAP_OR_LIST_SELECTED) ?? MAP_OR_LIST_SELECTED.LIST,
            );
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (propertiesPageContainer && propertiesPageContainer?.current?.scrollTop) {
                scrollTop(propertiesPageContainer, propertiesPageContainer.current.scrollTop);
            }
        }, 250);
    }, [listStore.selectedPage]);

    if (propertiesStore.isPropertiesPage404()) {
        deleteItemInitialSate("is404");
        return <PageNotFound />;
    }

    if (!location) return null;
    return (
        <div className={"position_relative"} ref={propertiesPageContainer}>
            <PropertiesMeta purpose={propertiesStore.purpose} />
            <div className={clsx(styles.placeholder, "flex_row_center", { [styles.scrolled]: scrollPosition })}>
                <Suspenser noText noSpinner>
                    <LazyPropertiesSearchBar />
                </Suspenser>
            </div>
            {mapOrListSelected === MAP_OR_LIST_SELECTED.LIST && (
                <div className={clsx(styles.header, "ph_20")}>
                    <PropertiesViewHeader />
                </div>
            )}
            <FeaturedPropertiesSrpsSection purpose={propertiesStore.purpose ?? "BOTH"} />
            <PropertiesView />
        </div>
    );
};

export const PropertiesPage = () => {
    return (
        <PageRendererContext>
            <_PropertiesPage />
        </PageRendererContext>
    );
};
