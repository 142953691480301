import { PARAMS_NORTH_PREFIX, PARAMS_PAGE_PREFIX, PARAMS_ZOOM_PREFIX } from "_common/_utils/searchUtils";
import { isPropertyType } from "properties/searchBar/filters/_utils/filtersUtils";
import { GEO_ZONE_TYPE, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls, removeAccentFromString } from "_common/_utils/alphaNumUtils";
import { TLang } from "_configs/sharedConfig";

export function getPatternOfGeoZone(urlSplit: string[], lang: TLang) {
    const isProvinceOrCityOrNeighborhoodPagePattern =
        urlSplit.length >= 1 &&
        urlSplit[0].length === 2 &&
        !urlSplit[0].startsWith(PARAMS_NORTH_PREFIX) &&
        !urlSplit[0].startsWith(PARAMS_ZOOM_PREFIX) &&
        !urlSplit[0].startsWith(PARAMS_PAGE_PREFIX) &&
        !isPropertyType(urlSplit[0], lang);

    const isCityOrNeighborhoodPagePattern =
        isProvinceOrCityOrNeighborhoodPagePattern &&
        urlSplit.length >= 2 &&
        !urlSplit[1].startsWith(PARAMS_NORTH_PREFIX) &&
        !urlSplit[1].startsWith(PARAMS_ZOOM_PREFIX) &&
        !urlSplit[1].startsWith(PARAMS_PAGE_PREFIX) &&
        !isPropertyType(urlSplit[1], lang) &&
        urlSplit[1] !== "";
    console.log("urlSplit", urlSplit, isPropertyType(urlSplit[2], lang), lang);
    const isNeighborhoodPagePattern =
        isCityOrNeighborhoodPagePattern &&
        urlSplit.length >= 3 &&
        !isPropertyType(urlSplit[2], lang) &&
        !urlSplit[2].startsWith(PARAMS_ZOOM_PREFIX) &&
        !urlSplit[2].startsWith(PARAMS_NORTH_PREFIX) &&
        !urlSplit[2].startsWith(PARAMS_PAGE_PREFIX);
    return { isProvinceOrCityOrNeighborhoodPagePattern, isCityOrNeighborhoodPagePattern, isNeighborhoodPagePattern };
}

export function getSearchResultPageUrlFromGeoZonePurposeAndType(
    geoZone?: Pick<TGeoZoneMdl, "type" | "address">,
    purpose: PROPERTY_PURPOSE = PROPERTY_PURPOSE.BUY,
    type = "",
    lang?: TLang,
) {
    let searchResultUrlFunc = URLS.buy;
    if (purpose === PROPERTY_PURPOSE.RENT) searchResultUrlFunc = URLS.rent;

    if (!geoZone) {
        return searchResultUrlFunc(lang, type);
    } else if (geoZone.type === GEO_ZONE_TYPE.PROVINCE) {
        return searchResultUrlFunc(
            lang,
            removeAccentFromString(geoZone.address.province).replace(" ", "").toLowerCase(),
            type,
        );
    } else if (geoZone.type === GEO_ZONE_TYPE.CITY) {
        return searchResultUrlFunc(
            lang,
            removeAccentFromString(geoZone.address.province).replace(" ", "").toLowerCase(),
            reformatStringForUrls(geoZone.address.city),
            type,
        );
    } else if (geoZone.type === GEO_ZONE_TYPE.NEIGHBORHOOD) {
        return searchResultUrlFunc(
            lang,
            removeAccentFromString(geoZone.address.province).replace(" ", "").toLowerCase(),
            reformatStringForUrls(geoZone.address.city),
            reformatStringForUrls(geoZone.address.neighbourhood),
            type,
        );
    }
    return searchResultUrlFunc();
}
