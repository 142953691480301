import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { VirtualAssistantFormStepper } from "virtualAssistant/VirtualAssistantFormStepper";
import { VirtualAssistantStepOneForm } from "virtualAssistant/VirtualAssistantStepOneForm";
import { VirtualAssistantStepTwoForm } from "virtualAssistant/VirtualAssistantStepTwoForm";
import { VirtualAssistantStepThreeForm } from "virtualAssistant/VirtualAssistantStepThreeForm";
import { VirtualAssistantFinalStepForm } from "virtualAssistant/VirtualAssistantFinalStepForm";
import { VirtualAssistantStepProgression } from "virtualAssistant/VirtualAssistantStepProgression";
import styles from "./_css/virtualAssistantForm.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { scrollTop } from "_common/_utils/ScrollTop";
import { Container } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import home from "shared/assets/images/virtual-assistant/home.jpg";
import { ArrowCtaIcon } from "properties/propertyPage/_components/ArrowCtaIcon";
import { PROPERTY_PURPOSE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { priceRangeToFilters } from "properties/searchBar/filters/_utils/priceUtils";
import { propertyTypesToFilters } from "properties/searchBar/filters/_utils/propertyTypeUtils";
import { roomsRangeToFilters } from "properties/searchBar/filters/_utils/roomsUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { surfaceRangeToFilters } from "properties/searchBar/filters/_utils/surfaceUtils";
import { userStore } from "users/_stores/userStore";
import { useHistory, useLocation } from "react-router-dom";

export type TVirtualAssistantForm = {
    purpose: PROPERTY_PURPOSE;
    geoZone: string[];
    propertyType: PROPERTY_TYPE[];
    price: [number | string, number | string];
    allowPriceUponRequestProject: boolean;
    bedrooms: number[];
    bathrooms: number[];
    surface: [number | string, number | string];
    when: "now" | "2-6m" | "6m-1y" | "1y+";
    comment: string;
    firstName: string;
    lastName: string;
    email: string;
    phones: string;
};

function getStepFromParams(search: string) {
    const searchParams = new URLSearchParams(search);
    return searchParams.get("step");
}

export function VirtualAssistantForm() {
    const history = useHistory();
    const location = useLocation();
    const [filters, setFilters] = useState<TFilter[]>([]);
    const { t } = useTranslation();
    const step = Number(getStepFromParams(location.search));
    console.log("step change", step);
    const form = useForm<TVirtualAssistantForm>({
        mode: "all",
        defaultValues: {
            purpose: PROPERTY_PURPOSE.BUY,
            geoZone: [],
            propertyType: [PROPERTY_TYPE.condo],
            price: ["", ""],
            allowPriceUponRequestProject: true,
            bedrooms: [],
            bathrooms: [],
            surface: ["", ""],
            when: "now",
            comment: "",
            firstName: userStore.user?.firstName ?? "",
            lastName: userStore.user?.lastName ?? "",
            email: userStore.user?.email ?? "",
            phones: userStore.user?.firstName ?? "",
        },
    });

    useEffect(() => {
        setTimeout(() => scrollTop(), 200);
    }, [location.search]);

    const changeStep = (step: number) => {
        history.push({ pathname: location.pathname, search: `?step=${step}` });
    };
    async function onSubmit(data: TVirtualAssistantForm) {
        const filters: TFilter[] = [];
        filters.push(
            ...priceRangeToFilters({
                min: data.price[0].toString(),
                max: data.price[1].toString(),
            }),
        );

        filters.push(...propertyTypesToFilters(data.propertyType));

        if (data.bedrooms.length > 0) {
            filters.push(
                ...roomsRangeToFilters({
                    min: data.bedrooms[0].toString(),
                    max: (data.bedrooms?.[1] ?? data.bedrooms?.[0]).toString(),
                    filterKey: "bedrooms",
                }),
            );
        }
        if (data.bathrooms.length > 0) {
            filters.push(
                ...roomsRangeToFilters({
                    min: data.bathrooms[0].toString(),
                    max: (data.bathrooms?.[1] ?? data.bathrooms?.[0]).toString(),
                    filterKey: "bathrooms",
                }),
            );
        }

        if (data.surface.length > 0) {
            filters.push(
                ...surfaceRangeToFilters({
                    min: data.surface[0].toString(),
                    max: (data.surface?.[1] ?? data.surface?.[0]).toString(),
                }),
            );
        }

        if (data.geoZone.length > 0) {
            filters.push({
                id: "location",
                type: TFilterType.ZONES,
                value: data.geoZone,
            });
        }
        setFilters(filters);
        changeStep(step + 1);
    }

    return (
        <>
            {step === 0 ? (
                <Container>
                    <div className={clsx(styles.initialStepContainer, "flex_row")}>
                        <div className={"flex_column justifyContent_center"}>
                            <h1 className={styles.title}>{t("virtualAssistant.title")}</h1>
                            <div className={styles.subtitle}>{t("virtualAssistant.subtitle")}</div>
                            <div className="flex_row">
                                <div className={styles.btn} onClick={() => changeStep(1)}>
                                    {t("virtualAssistant.start")} <ArrowCtaIcon />
                                </div>
                            </div>
                        </div>
                        <LazyLoadImage className={styles.homeImg} width={500} height={360} src={home} />
                    </div>
                </Container>
            ) : (
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className={clsx(styles.formContainer)}>
                        <div
                            className={clsx({
                                [styles.step1Form]: step === 1,
                                [styles.step2Form]: step === 2,
                                [styles.step3Form]: step === 3,
                                [styles.step4Form]: step === 4,
                            })}
                        >
                            <VirtualAssistantStepProgression step={step} />
                            <div className={styles.stepContainer}>
                                <div className={clsx({ hidden: step !== 1 })}>
                                    <VirtualAssistantStepOneForm />
                                </div>
                                <div className={clsx({ hidden: step !== 2 })}>
                                    <VirtualAssistantStepTwoForm />
                                </div>
                                <div className={clsx({ hidden: step !== 3 })}>
                                    <VirtualAssistantStepThreeForm />
                                </div>
                                <div className={clsx({ hidden: step !== 4 })}>
                                    {step === 4 && (
                                        <VirtualAssistantFinalStepForm
                                            filters={filters}
                                            goToStepOne={() => changeStep(1)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <VirtualAssistantFormStepper
                            step={step}
                            onNext={
                                step <= 4
                                    ? () => {
                                          if (step <= 2) {
                                              changeStep(step + 1);
                                          }
                                      }
                                    : undefined
                            }
                            onPrevious={
                                step > 1
                                    ? () => {
                                          changeStep(step - 1);
                                      }
                                    : undefined
                            }
                        />
                    </form>
                </FormProvider>
            )}
        </>
    );
}
